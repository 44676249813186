import React from "react"

import Layout from "../../containers/Layout";
import Meta from "../../containers/Meta";
import PageHeader from "../../components/PageHeader";
import BackToTop from "../../components/BackToTop";

import * as styles from "../../sass/contact.module.scss";

const ContactCompletePage = () => {

  return (
    <Layout>
      <Meta title={"お問い合わせ完了"} />

      <PageHeader title={"お問い合わせ完了"} />

      <div className={styles.content}>
        <div className={styles.formContainer}>
          <p>
            お問い合わせいただきありがとうございます。<br />
            担当者より返信いたしますので、しばらくお待ちくださいますようお願いいたします。
          </p>
        </div>
      </div>

      <BackToTop />
    </Layout>
  );
};

export default ContactCompletePage;